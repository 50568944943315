import React from 'react';

import NavigationBar from './NavigationBar';
import IconEquipment from './IconEquipment.svg';
import IconPartners from './IconPartners.svg';
import IconQuality from './IconQuality.svg';


function App() {
  return (
    <>
      <NavigationBar />

      <div id="top" className="full-height-block container-wrapper-top text-center">
        <div className="full-height-block--cell container-overlay">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-10">
                <h1 className="mb-4">Alumiiniumi keevitus ja keevistoodete valmistamine</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <a href="#loo" className="btn btn-lg btn-success text-uppercase rounded-0 my-2 mx-3">
                  Tootmine Lool
                </a>
                <a href="#kamari" className="btn btn-lg btn-success text-uppercase rounded-0 my-2 mx-3">
                  Tootmine Kamaris
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="ettevottest" className="full-height-block">
        <div className="full-height-block--cell container-wrapper-highlight text-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-sm-10">
                <p>
                  OÜ METAGRUPP on 1998 aastal asutatud eraettevõte,
                  mille tegevusalaks on alumiiniumist ja teistest kergmetallidest keevistoodete valmistamine.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="vaartused" className="full-height-block">
        <div className="full-height-block--cell container-wrapper-secondary">
          <div className="values-block">
            <p>
              <img src={IconQuality} alt="Kvaliteet" />
              Meie areng on suunatud kaasaegsete keevitustehnoloogiate rakendamisele,
              partnerlussuhetele koostöövõrgus ja avatusele rahvusvahelisel tasandil.
              Keskendume oma klientide soove rahuldades kvaliteedile ja tähtaegadele.
            </p>
          </div>
          <div className="values-block">
            <p>
              <img src={IconPartners} alt="Koostöö" />
              Oma töös oleme seadnud eesmärgiks ka teiste firmade tööde ja teenuste kaasamist nii,
              et tellija saab meilt vastavalt oma soovidele,
              kas tervikliku toote või allhankena alumiiniumi töötlemise teenustöö.
            </p>
          </div>
          <div className="values-block">
            <p>
              <img src={IconEquipment} alt="Kogemus" />
              Omame kogemust kergmetallist toodete töötlemise ja allhanketööde teostamise alal ja
              oleme suutelised keevitama alumiiniumist lehti ja
              profiile materjali paksusega <span className="text-nowrap">1,0 mm - 35,0 mm.</span>
            </p>
          </div>
        </div>
      </div>

      <div id="kontakt" className="full-height-block">
        <div className="full-height-block--cell container-wrapper-contact text-center">
          <div className="container">
            <div className="row">
              <div className="col-sm-4">
                <h4 className="my-4">Metagrupp OÜ</h4>
                <p className="contact-split">
                  Reg. nr: 10440094<br />
                  KMKR: EE100045619
                </p>
              </div>
              <div className="col-sm-4">
                <h4 className="my-4">Üldkontakt</h4>
                <p className="contact-split">
                  Tel: +372 50 10 430<br />
                  <a href="mailto:info@metagrupp.ee">info@metagrupp.ee</a>
                </p>
              </div>
              <div className="col-sm-4">
                <h4 className="my-4">Postiaadress</h4>
                <p>
                  Kauri tee 12a-4<br />
                  Alliku küla, 76403<br />
                  Saue Vald, Harju maakond
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="loo" className="full-height-block">
        <div className="full-height-block--cell">
          <div id="mapLoo" className="map" />

          <div className="container-wrapper-contact text-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <h4 className="my-4">Tootmine Lool</h4>
                  <p>
                    Saha tee 18<br />
                    Loo alevik, 74201<br />
                    Jõelähtme vald, Harju maakond
                  </p>
                </div>
                <div className="col-sm-6">
                  <h4 className="my-4">Kontakt</h4>
                  <p>
                    Jaanus Vapper<br />
                    +372 53 443 102<br />
                    <a href="mailto:jaanus@metagrupp.ee">jaanus@metagrupp.ee</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="kamari" className="full-height-block">
        <div className="full-height-block--cell">
          <div id="mapKamari" className="map" />

          <div className="container-wrapper-contact text-center">
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <h4 className="my-4">Tootmine Kamaris</h4>
                  <p>
                    Tööstuse 17<br />
                    Kamari alevik, 48030<br />
                    Põltsamaa vald, Jõgeva maakond
                  </p>
                </div>
                <div className="col-sm-6">
                  <h4 className="my-4">Kontakt</h4>
                  <p>
                    Indrek Eensalu<br />
                    +372 50 509 86<br />
                    <a href="mailto:kamari@metagrupp.ee">kamari@metagrupp.ee</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
