import React, {useState} from 'react';

import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,
} from 'reactstrap';


function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);

  return (
    <Navbar fixed="top" light color="light" expand="md">
      <div className="container">
        <NavbarBrand href="#top">
          meta<span className="navbar-brand-second">grupp</span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle}/>
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <NavLink href="#ettevottest" onClick={close}>Ettevõttest</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#vaartused" onClick={close}>Väärtused</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#kontakt" onClick={close}>Kontakt</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#loo" onClick={close}>Tootmine Lool</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#kamari" onClick={close}>Tootmine Kamaris</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </div>
    </Navbar>
  );
}

export default NavigationBar;
