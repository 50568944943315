// Polyfilling based on https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md
import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';

import {animateScroll} from 'react-scroll';

import App from './App';
import './index.scss';
import reportWebVitals from './reportWebVitals';


ReactDOM.render(
  // TODO: Add strict mode back once the issue with reactstrap Collapse (Transition) gets fixed or element replaced
  // https://github.com/reactstrap/reactstrap/issues/1962
  // <React.StrictMode>
  //   <App/>
  // </React.StrictMode>,
  <App/>,
  document.getElementById('root')
);


const anchors = document.querySelectorAll('a[href*="#"]:not([href="#"])');
for (let i = 0; i < anchors.length; i++) {
  const target = anchors[i];
  target.addEventListener('click', (e) => {
    const anchor = target.getAttribute('href');
    if (!anchor) {
      return;
    }
    const splitHref = anchor.split('#');
    const elementHash = splitHref[splitHref.length - 1];
    const element = document.getElementById(elementHash);
    if (!element) {
      return;
    }
    animateScroll.scrollTo(element.offsetTop);
  });
}


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
